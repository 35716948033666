import axios from 'axios';
import firebase from 'firebase';

// const API = axios.create({
//   baseURL: window.location.hostname.includes('Integratiepartners')
//     ? 'http://localhost:3000'
//     : 'http://localhost:3000',
//   timeout: 30000,
// });


const API = axios.create({
  baseURL: window.location.hostname.includes('Integratiepartners')
    ? 'https://digitalsignbackend.waldent.nl'
    : 'https://digitalsignbackend.waldent.nl',
  timeout: 30000,
});

const refreshInterval = 1000 * 60 * 5;
let fireToken = {
  added_on: null,
  token: null,
};

API.interceptors.request.use(async (config) => {
  const existingToken = document.cookie.split('; ').find(row => row.startsWith('token='));
  if (existingToken) {
    fireToken = JSON.parse(existingToken.split('=')[1]);
  }

  if (
    !fireToken.added_on ||
    new Date(new Date(fireToken.added_on).valueOf() + refreshInterval) < new Date()
  ) {
    let token;
    if (firebase.auth() && firebase.auth().currentUser) {
      token = await firebase.auth().currentUser.getIdToken(true);
    }
    if (token) {
      fireToken = {
        added_on: new Date(),
        token,
      };
      document.cookie = `token=${JSON.stringify(fireToken)}; path=/`;
    }
  }
  config.headers['fireToken'] = fireToken.token;

  return config;
});

export { API };
