<template>

  <div>
    <Menu />
    <v-container class="employee-container" fluid>
      <v-row>
        <AddEmployee v-if="showAddEmployee == true" />
        <div style="margin-top:10px;margin-left:180px" v-if="!showAddEmployee">
          <v-btn color="primary" @click="showAddEmployee = !showAddEmployee">
            Mederwerken toevoegen
          </v-btn>
        </div>
        <v-col cols="12">
          <v-container v-if="!showAddEmployee">
            <v-card>
              <v-card-title>
                Team
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Opzoeken"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-row>
                <v-col v-for="letter in letters" :key="letter" cols="auto">
                  <v-btn
                    :color="selectedLetter === letter ? 'primary' : ''"
                    @click="selectLetter(letter)"
                  >
                    {{ letter }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-container>
                <v-row>
                  <v-col
                    v-for="employee in filteredEmployees"
                    :key="employee.id"
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-card>
                      <v-img
                        :src="
                          employee.images[0]
                            ? `https://digitalsignbackend.waldent.nl/static/uploads/${employee.images[0].filename}`
                            : require('@/assets/logo.jpg')
                        "
                        height="240px"
                        class="file-icon"
                        contain
                        width="350"
                      >
                      </v-img>
                      <v-card-title>{{ employee.name }}</v-card-title>
                      <v-card-text>
                        <div>{{ employee.title }}</div>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          color="primary"
                          @click="toggleWorkdaysVisibility(employee.id)"
                          >Werkdagen bekijken</v-btn
                        >

                        <v-btn
                          color="error"
                          @click="deleteEmployee(employee.id)"
                          >Verwijderen</v-btn
                        >
                      </v-card-actions>

                      <div v-if="employee.showWorkdays">
                        <div v-for="(workday, index) in workdays" :key="index">
                          {{ workday }}
                   <input
  type="checkbox"
  :checked="employee.workdays.includes(workday)"
  @change="toggleWorkdays(employee.id, workday, $event.target.checked)"
/>


                        </div>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import apiTeam from "../api/apiTeam";
import AddEmployee from "../dashboard/AddEmployee";
import Menu from "./Menu.vue";
export default {
  components: {
    AddEmployee,
    Menu
  },
  data: () => ({
    showAddEmployee: false,
    showWorkdays: false,
    workdays: ["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag"],
    employees: [],
    search: "",
    selectedLetter: "",
    letters: [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ],
  }),

  async mounted() {
    try {
      const data = await apiTeam.getAllAdminTeams();
      this.employees = data.map((employee) => ({
        ...employee,
        showWorkdays: false,
        workdays: employee.workdays.map(workdayObj => workdayObj.name),
        selectedWorkdays: new Array(this.workdays.length).fill(false),
      }));
    } catch (error) {
      console.error(
        "Er is een fout opgetreden tijdens het laden van de teams:",
        error
      );
    }
  },

  computed: {
    filteredEmployees() {
      if (this.selectedLetter) {
        return this.employees.filter((employee) => {
          return employee.name.charAt(0).toUpperCase() === this.selectedLetter;
        });
      }
      return this.employees;
    },
  },

  methods: {
    toggleWorkdaysVisibility(employeeId) {
      const employee = this.employees.find((e) => e.id === employeeId);
      if (employee) {
        employee.showWorkdays = !employee.showWorkdays;
      }
    },
    toggleWorkdays(employeeId, workdayName, selected) {
      const employee = this.employees.find((e) => e.id === employeeId);
      if (employee) {
        if (selected) {
          employee.workdays.push(workdayName);
        } else {
          const index = employee.workdays.indexOf(workdayName);
          if (index !== -1) {
            employee.workdays.splice(index, 1);
          }
        }
        this.updateEmployee(employeeId);
      }
    },

    async deleteEmployee(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je de medewerker wil verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        try {
          await apiTeam.deleteTeam(id);
          await this.loadKavels();
          this.$swal.fire(
            "Verwijderd",
            "De medewerker is succesvol verwijderd",
            "success"
          );
        } catch (error) {
          console.error("Fout bij het verwijderen van de medewerker:", error);
        }
      }
    },
    async updateEmployee(employeeId) {
      const employee = this.employees.find((e) => e.id === employeeId);
      if (employee) {
        try {
          await apiTeam.updateTeam(employeeId, { workdays: employee.workdays });
          // handle success
        } catch (error) {
          // handle error
        }
      }
    },

    selectLetter(letter) {
      this.selectedLetter = letter;
    },
  },
};
</script>

<style scoped>
.employee-container {
  max-height: 1000px; /* Set an appropriate height */

}
</style>
