<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Instellingen
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          Timer instellen
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="timer"
            outlined
            label="Tijd instellen"
            suffix="Seconden"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="dialog = false">
            Afsluiten
          </v-btn>
          <v-btn color="green darken-1" style="color:white" @click="updateTimer()">
            Bijwerken
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import apiTImer from "../api/apiTimer";
export default {
  data() {
    return {
      dialog: false,
      timer: 0,
    };
  },
  async mounted() {
    await apiTImer.getTimer(this.timer).then((data) => {
      this.timer = data;
    });
  },
 
  methods:{
    async updateTimer() {
      try {
        const data = {
        timer: this.timer
      }
        const response = await apiTImer.updateTimer(data);
        console.log(response);
        this.tenant = response;
        this.$swal.fire("Gelukt!", "Timer is bijgewerkt!", "success");
        this.timer = await apiTImer.getTimer();
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Foutmelding!",
          "Het bijwerken is niet gelukt!",
          "error"
        );
      }
    },
  }
};
</script>
