<template>
  <div>
  <Menu />
  <v-container>
    <div class="toolbar">
   
    </div>

    <v-row class="justify-center">
      <v-col cols="12" md="6" style="margin-bottom: 5px; display: flex;">
        <v-text-field
          dense
          v-model="searchQuery"
          append-icon="mdi-magnify"
          label="Zoeken content"
          hide-details
          outlined
          class="search-field"
        ></v-text-field>
      </v-col>
    </v-row>
<div style="margin-bottom: 4px;">


    <v-btn color="primary" @click="showDialog = true"
      >+ Mededeling toevoegen</v-btn
    >
  </div>
    <v-dialog v-model="showDialog" max-width="700px">
      <v-card>
        <v-card-title>Nieuw veld aanmaken</v-card-title>
        <v-card-text>
          <v-text-field dense v-model="newFieldName" label="Naam"></v-text-field>
          <v-text-field
          dense
            v-model="newFieldOrder"
            label="Volgorde"
            type="number"
          ></v-text-field>
          <v-switch v-model="visible" label="Zichtbaar" inset></v-switch>
          <vue-editor v-model="newFieldContent"></vue-editor>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="addNewField">Aanmaken</v-btn>
          <v-btn text @click="showDialog = false">Annuleren</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-expansion-panels focusable>
      <v-expansion-panel v-for="field in filteredContentFields" :key="field.id" class="compact-panel">
        <v-expansion-panel-header>
        
            {{ field.name }}
            <v-spacer></v-spacer>
           
        
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row style="margin-top: 5px;">
            <v-col cols="6">
              <v-text-field
          dense
            v-model="field.name"
            outlined
           label="Titel"
          ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
          dense
            v-model="field.order"
            outlined
            label="Volgorde"
          ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn    elevation="2"
  small color="error"  @click.stop="removeField(field.id)">
             Verwijder tekst
            </v-btn>
            </v-col>
            
          </v-row>
         
          <v-switch v-model="field.visible" label="Zichtbaar" inset></v-switch>
         
          <vue-editor
            v-model="field.content"
            :id="field.id.toString()"
          ></vue-editor>
          <v-btn @click="updateIndividualContent(field)" color="primary"
            >Update {{ field.name }}</v-btn
          >
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="action-buttons">
    
    </div>
  </v-container>
</div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import apiContent from "../api/apiContent";
import Menu from "./Menu.vue"
export default {
  components: {
    VueEditor,
    Menu
  },
  data() {
    return {
      selectedImageFiles: [],
      searchQuery: "",
      visible: false,
      highestOrder: 0,
      showDialog: false,
      newFieldName: "",
      newFieldContent: "",
      newFieldOrder: 0,
      contentFields: [],
      nextFieldId: 1,
    };
  },
  computed: {
    filteredContentFields() {
      if (!this.searchQuery) return this.contentFields;

      const searchLowerCase = this.searchQuery.toLowerCase();

      return this.contentFields.filter((field) =>
        field.name.toLowerCase().includes(searchLowerCase)
      );
    },
  },

  async mounted() {
    await this.fetchData();
  },

  methods: {
    handleImageAdded(file) {
      this.selectedImageFiles.push(file);
      const placeholder = `![uploading image... ${this.selectedImageFiles.length}]`;
      this.newFieldContent += placeholder;
    },
    async fetchData() {
      try {
        const data = await apiContent.getAllContent();
        if (Array.isArray(data)) {
          this.contentFields = data.map((field) => ({
            id: field.id,
            name: field.name,
            order: field.order,
            visible: field.visible,
            content: field.content,
          }));
          this.nextFieldId = Math.max(...data.map((field) => field.id)) + 1;
          this.highestOrder = Math.max(...data.map((field) => field.order));
        }
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    },
    resetFields() {
      this.newFieldName = "";
      this.newFieldOrder = "";
      this.newFieldContent = "";
      this.visible = "";
    },

    async addNewField() {
      try {
        let content = this.newFieldContent;
        for (let i = 0; i < this.selectedImageFiles.length; i++) {
          const file = this.selectedImageFiles[i];
          const formData = new FormData();
          formData.append("image", file);

          const response = await apiContent.uploadImage(formData);
          const imageUrl = response.data.imageUrl;
          content = content.replace(
            `![uploading image... ${i + 1}]`,
            `<img src="${imageUrl}"/>`
          );
        }

        const data = {
          name: this.newFieldName,
          order: this.newFieldOrder,
          content: content,
          visible: this.visible,
        };

        await apiContent.createContent(data);
        await this.fetchData();
        this.showDialog = false;
        this.$swal.fire({
          title: "The Content was successful.",
          text: "Updating ",
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error(error);
        this.$swal.fire({
          title: "Houston, we have a problem!",
          text: `Error message: ${error.message}`,
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      }
    },
    async removeField(id) {
      try {
        let confirmation = await this.$swal.fire({
          title: "Are you sure?",
          text: "Do you really want to delete this content?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ja, verwijderd!",
          cancelButtonText: "Nee, Annuleer!",
        });
        if (confirmation.value) {
          await apiContent.deleteContent(id);
          await this.fetchData();

          this.$swal.fire({
            title: "De content is verwijderd.",
            text: "Updating...",
            icon: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          });
        } else {
          return;
        }
      } catch (error) {
        this.$swal.fire({
          title: "Houston, we have a problem!",
          text: `Error message: ${error.message}`,
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      }
    },
    async updateIndividualContent(field) {
      try {
        const data = {
          name: field.name,
          order: field.order,
          content: field.content,
          visible: field.visible,
        };
        await apiContent.updateContent(field.id, data);
        await this.fetchData();
        this.$swal.fire({
          title: "Content is geupdate",
          text: `Content met ID ${field.name} is succesvol geupdate.`,
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      } catch (error) {
        this.$swal.fire({
          title: "Houston, we have a problem!",
          text: `Error message: ${error.message}`,
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
      }
    },
  },
};
</script>

<style scoped>
.text-center {
  padding-top: 1%;
  text-align: center;
}
#editor {
  height: 350px;
  width: 150px;
}
</style>
