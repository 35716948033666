
<template>
  <Team />
</template>

<script>
import Team from "@/components/dashboard/Team";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Team,
  },
  metaInfo: {
    title: "Integratiepartners digital | Werkdagen toevoegen",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>