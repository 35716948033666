
<template>
  <Content />
</template>

<script>
import Content from "@/components/dashboard/Content";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Content,
  },
  metaInfo: {
    title: "Integratiepartners digital sign login",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>