<template>
    <div >
      <Menu />
  <v-container>
   
    <v-row justify="center">
      <v-col cols="12" sm="6">
        <v-text-field
          label="Voer URL in"
          v-model="url"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-btn color="primary" @click="generateQRCode">Genereer QR-code</v-btn>
    </v-row>

    <v-row justify="center" v-if="qrCodeDataUrl">
      <v-col cols="12" sm="6">
        <v-img :src="qrCodeDataUrl" aspect-ratio="1"></v-img>
        <v-btn color="success" @click="downloadQRCode">Download QR-code</v-btn>
      </v-col>
    </v-row>
  </v-container>
  </div>
</template>

<script>
import QRCode from 'qrcode'
import Menu from "./Menu.vue"
export default {
  components: {
    Menu
  },
  data() {
    return {
      url: '',
      qrCodeDataUrl: null,
    }
  },
  methods: {
   
    async generateQRCode() {
      try {
        this.qrCodeDataUrl = await QRCode.toDataURL(this.url)
      } catch (error) {
        console.error('Fout bij het genereren van QR-code:', error)
      }
    },
    downloadQRCode() {
      const link = document.createElement('a')
      link.href = this.qrCodeDataUrl
      link.download = 'qr-code.png'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
</script>

<style>
/* U kunt hier optioneel extra CSS-stijlen toevoegen */
</style>
